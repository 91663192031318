<script setup lang="ts">
import { ref } from "vue";
import event from '@/events/eventBus';
import PopupModal from '@/components/ui/headlessui/PopupModal.vue';
import SimpleButton from '@/components/ui/SimpleButton.vue';
import { router } from '@inertiajs/vue3'

type PropTypes = {
    idleDuration?: number;
    // finalDuration?: number;
}

const props = withDefaults(defineProps<PropTypes>(), {
    idleDuration: 1200, //seconds, Total duration before showing popup
    // finalDuration: 120, //seconds, Before redirected to homepage
})

// const markerStore = useMarkerStore();
// const roomStore = useRoomStore();
// const page = usePage().props;

const showPopup = ref(false);


function cancelConfiguration () {
    event.emit('sessionStop', {});
    router.visit(route('client.start'));
};
// const onidle = async () => {
//     markerStore.$reset();
//     roomStore.clientPackageId = null;
//     event.emit('sessionStop', {});
//     if (page.preferred_locale) {
//         await updateLocale(page.preferred_locale);
//     }
//     router.visit(route('client.start'), { replace: false });
// };
</script>

<template>
     <v-idle v-show="false"
            @idle="showPopup = true"
            :duration="idleDuration"
            :events="['keydown', 'mousedown', 'touchstart']"/>

    <PopupModal v-if="showPopup"
                @close="showPopup = false"
                show-close-button
                :title="$t('inactivity_title')"
                data-cy="idle-popup"
    >
        <p class="flex flex-wrap text-gray-500">
            {{ $t('inactivity_message')}}
        </p>
        <div class="flex justify-end mt-6 space-x-2">
            <SimpleButton
                @click="cancelConfiguration"
                type="outline"
                data-cy="dialog-confirm"
            >
                {{ $t('shut_down') }} 
            </SimpleButton>
            <SimpleButton
                @click="showPopup = false"
                type="primary"
            >
            {{ $t('continue')}}
            </SimpleButton>
        </div>
        <!-- <p class="flex flex-wrap text-gray-500">
            {{ $t('inactivity_message')}}
                <v-idle @idle="onidle" :duration="finalDuration" :events="[]" class="mx-2"/>
            {{ $t('seconds')}}.
        </p> -->
    </PopupModal>
</template>
