import ObjectRoom from "@/events/ObjectRoom";
import event from "@/events/eventBus";
import { useRoomStore, useGeneralStore } from "@/stores";
import { getClosestWall, removeExistingPoints } from "@/utils/pixiFloorplan/Marker";
import { snapToAngle } from "@/utils/objectUtils";
import { Container, Graphics, BitmapText, ILineStyleOptions } from "pixi.js";
import type { Room } from "@/types/room.types";
import type { Coordinates } from "@/types/coordinates.types";
import type { ShapePoint } from "@/types/pixishapepoints.types";
import * as Collision from '@/utils/pixiFloorplan/Collision';
import { debugCollision } from "@/types/collision.types";
import { Wall } from "@/utils/classes/Wall";
import ObjectScene from "@/events/ObjectScene";
import { loadBitMapFont } from '@/fonts/LoadBitmapFont';

const DEFAULT_LINE_STYLE = {
	width: 10,
	color: 0x1f262a,
	alpha: 1,
	alignment: 1,
}

/**
 * Draw the pixi walls and draw the room in unity
 */
export function DrawRoom(room: Room, container: Container): Graphics {
    loadBitMapFont();

    const og_walls = room.floorplan_data.walls;

    //Add walls to collision tree
    og_walls.forEach(wall => {
        Collision.insertWallCollisionTree(wall, wall.free)
    })

    //Prepare Unity-compatible walls, we need to flip the y coordinate
    const unityWalls = getUnityWalls(og_walls);
    //Get the roompoints again with flipped y values
    const unityPoints = getRoomPoints(unityWalls);
    drawUnityRoom(unityWalls, unityPoints, room.room_height);

    //Draw main Wall graphic, outer walls and free walls
    return setupWalls(og_walls, container);
}

function setupWalls(walls: Wall[], container: Container): Graphics {
    let wallGraphics = new Graphics();
    wallGraphics.eventMode = 'auto';
    wallGraphics.zIndex = 110;

    const { roomWalls, freeWalls } = separateWalls(walls);

    drawOuterWalls(roomWalls, wallGraphics, container);

    freeWalls.forEach(wall => {
        const wallGraphic = drawSingleWall(wall, container);
        wallGraphics.addChild(wallGraphic);
    });

    container.addChild(wallGraphics);

    return wallGraphics;
}

// Separate walls into room walls and free walls
function separateWalls(walls: Wall[]): { roomWalls: Wall[]; freeWalls: Wall[] } {
    const freeWalls = walls.filter((wall) => wall.free === true);
    const roomWalls = walls.filter((wall) => !wall.free || wall.free === false);
    return { roomWalls, freeWalls };
}

function drawOuterWalls(walls: Wall[], wallGraphics: Graphics, container: Container){
    const { wallThickness, wallColor } = useRoomStore();

    walls.forEach((wall) => {
        let firstWall = walls.indexOf(wall) === 0;
         drawWall(wallGraphics, wall, firstWall, 100, { 
            width: wallThickness, 
            color: wallColor, 
            alignment: 1
        });

        drawInteractionLayer(wall, wallThickness, wallColor, wallGraphics);

        //Init the wall measurement
        setWallMeasurements(container, wall);
    });

    wallGraphics.closePath();
}

function drawInteractionLayer(
    wall: Wall,
    thickness: number,
    color: number,
    wallGraphics: Graphics
): void {
    const interactionGraphics = new Graphics();
    interactionGraphics.eventMode = 'static';

    interactionGraphics.beginFill(color);
    interactionGraphics.drawRect(-wall.getLength() / 2, -thickness, wall.getLength(), thickness);
    interactionGraphics.endFill();

    const wallCenter = wall.getCenter();
    interactionGraphics.x = wallCenter.x;
    interactionGraphics.y = wallCenter.y;
    interactionGraphics.angle = wall.getAngle();

    interactionGraphics.wall_id = wall.id;
    interactionGraphics.free = false;

    wallGraphics.addChild(interactionGraphics);
}

/**
 * draw a single free standing wall
 */
export function drawSingleWall(wall: Wall, container: Container){
    const { wallThickness, wallColor } = useRoomStore();

    const graphics = drawWall(undefined, wall, true, 110, {width: wallThickness, color: wallColor, alignment: 0.5});
    graphics.hitArea = graphics.getBounds();
    graphics.wall_id = wall.id;
    graphics.free = true;

    //Init the wall measurement
    setWallMeasurements(container, wall);

    return graphics;
}

function drawWall(graphic = new Graphics(), wall: Wall, newWall = false, zIndex: number, lineConfig: Partial<ILineStyleOptions> = null){
	//When standalone or free wall,
	if(newWall){
		graphic.lineStyle( { ...DEFAULT_LINE_STYLE, ...(lineConfig || {}) });
		graphic.moveTo(wall.from.x, wall.from.y);
	}

	graphic.lineTo(wall.to.x, wall.to.y);
	graphic.zIndex = zIndex;

	return graphic;
}
/**
 * Move the wall inside the room
 * Or snap to closest wall when close enough to stay inside the room
 */
export function onDragMoveWall(draggable: any, mousePosition: Coordinates, shapePoints, container){
    const wallId = draggable.wall_id;

    const roundedMousePosition = roundMousePosition(mousePosition);

    //Remove points for changing the size of the wall
    removeExistingPoints(shapePoints, container);

    //To update a wall position you need to redraw the line
    updateWallByDragging(roundedMousePosition, wallId);

    redrawWall(draggable, wallId);
}

/**
 * Redraw an existing wall
 */
export function redrawWall(graphic: any, identifier: number){
    const roomStore = useRoomStore();
    const currentWall = roomStore.getWall(identifier);
    const wallThickness = roomStore.wallThickness;
    const wallColor = roomStore.wallColor;

    graphic.clear();
    graphic.lineStyle(wallThickness, wallColor, 1);
    graphic.moveTo(currentWall.from.x, currentWall.from.y);
    graphic.lineTo(currentWall.to.x, currentWall.to.y);
    graphic.zindex = 100;
    graphic.hitArea = graphic.getLocalBounds();
}

/**
 * Update a free standing wall by dragging the wall around
 */
export function updateWallByDragging(
    mousePosition: Coordinates,
    wallId: number,
    ) {
    const roomStore = useRoomStore();
    const currentWall = roomStore.getWall(wallId);

    const currentWallBody = Collision.findWallBody(wallId);

    const currentWallLength = currentWall.getLength();
    const closestWall = getClosestWall(mousePosition, false, [wallId]);

    const innerVector = closestWall.getInnerVector();
    const isSame = isSameAngle(currentWall.getAngle(), closestWall.getAngle());

    let newPos = mousePosition;

    const cosAngle = Math.cos(currentWall.getAngleRadians());
    const sinAngle = Math.sin(currentWall.getAngleRadians());
    const distance = ((innerVector.x * cosAngle) + (innerVector.y * sinAngle)) * currentWallLength;

    // if(!Collision.calculateDistWallToObject(closestWall, mousePosition, Math.abs(distance / 2))){
    if(isSame && !Collision.calculateDistWallToObject(closestWall, mousePosition, 10)){
        newPos = Collision.stayInsideRoom(mousePosition, closestWall, {
            side: '',
            object: Math.abs(distance / 2),
            offset: roomStore.wallThickness / 2
        });
    }

    currentWallBody.setPosition(newPos.x, newPos.y);
    debugCollision.update()

    updateWallPoints(currentWall, newPos);
}

function isSameAngle(angle1, angle2, range = 5) {
    // Normalize angles to the same range for consistency
    angle1 = ((angle1 % 180) + 180) % 180; // Normalize angle1 to [0, 180)
    angle2 = ((angle2 % 180) + 180) % 180; // Normalize angle2 to [0, 180)

    // Check if the absolute difference between angles is within the range
    return Math.abs(angle1 - angle2) <= range;
}

function updateWallPoints(currentWall: Wall, coordinates: Coordinates) {
    const offsetX = (currentWall.to.x - currentWall.from.x)/ 2;
    const offsetY = (currentWall.to.y - currentWall.from.y)/ 2;

    currentWall.from.x = coordinates.x - offsetX;
    currentWall.from.y = coordinates.y - offsetY;
    currentWall.to.x = coordinates.x + offsetX;
    currentWall.to.y = coordinates.y + offsetY;
}

/**
 * Rezise a wall by moving the pointers around
 */
export function updateWallByPointers(mousePosition: Coordinates, point: ShapePoint): void{
    const wallId = point.identifier;
    const roomStore = useRoomStore();
    const currentWall = roomStore.getWall(wallId);
    const wallThickness = roomStore.wallThickness;
    const isFirstPoint = point.id === 1;
    const closestWall = getClosestWall(mousePosition, false);

    //Update the wall by mousePosition
    let newPos = updateWall(isFirstPoint, currentWall, mousePosition)

    //When you get to close to the outer wall, ensure you can't drag out
    if(!Collision.calculateDistWallToObject(closestWall, mousePosition, 5)){
        const { x, y } = snapToClosestOuterWall(mousePosition, closestWall, wallThickness);

        //Update the wall by locked x,y coordinated, and disable snapping
        newPos = updateWall(isFirstPoint, currentWall, {x,y}, false)
    }

    point.x = newPos.x;
    point.y = newPos.y;

    Collision.removeWallCollisionTree(wallId);
    Collision.insertWallCollisionTree(currentWall, true)
}

function snapToClosestOuterWall(
    mousePosition: Coordinates,
    closestWall: Wall,
    wallThickness: number,
    ): Coordinates {
    //Ensure you stay inside the room
    const { x, y } = Collision.stayInsideRoom(mousePosition, closestWall);

    //Add displacement to the coordinates so the wall would snap inside the room wall
    const innerVector = closestWall.getInnerVector();
    const displacementX = innerVector.x * wallThickness / 2;
    const displacementY = innerVector.y * wallThickness / 2;

    return {
        x: x - displacementX,
        y: y - displacementY,
    };
}

function updateWall(
    isFirstPoint: boolean,
    currentWall: Wall,
    mousePosition: Coordinates,
    useSnapping = true,
    ): Coordinates {
    const minWallLength = 50;

    // Update the wall coordinates based on the dragged point's new position
    if (isFirstPoint) {
        currentWall.from.x = mousePosition.x;
        currentWall.from.y = mousePosition.y;
    }else{
        currentWall.to.x = mousePosition.x;
        currentWall.to.y = mousePosition.y;
    }

    // Calculate the angle of the wall
    const angleRadians = currentWall.getAngleRadians();
    const snappedAngle = useSnapping ? snapToAngle(angleRadians, 45, 5) : angleRadians;

    // Update the wall coordinates based on the snapped angle
    const length = Math.max(currentWall.getLength(), minWallLength);

    if (isFirstPoint) {
        currentWall.from.x = currentWall.to.x - length * Math.cos(snappedAngle);
        currentWall.from.y = currentWall.to.y - length * Math.sin(snappedAngle);

        return currentWall.from;
    }

    currentWall.to.x = currentWall.from.x + length * Math.cos(snappedAngle);
    currentWall.to.y = currentWall.from.y + length * Math.sin(snappedAngle);

    return currentWall.to;
}

export function roundMousePosition(mousePosition: Coordinates): Coordinates {
    return {
        x: Math.round(mousePosition.x),
        y: Math.round(mousePosition.y),
    };
}

/**
 * Change all y coordinates to negative, unity's y axis starts from bottom left
 * Pixijs y axis starts from top left
 */
export function getUnityWalls(walls: Wall[]): Wall[]{
    return walls.map((wall) => {
        const { from, to } = flipWallCoordinatesForUnity(wall);

        return {
            id: wall.id,
            free: wall.free,
            from: from,
            to: to,
            height: wall.height,
        };
    });
}

export function flipWallCoordinatesForUnity(position_data: {from: Coordinates; to: Coordinates}){
    return {
        from: {
            x: position_data.from.x,
            y: position_data.from.y * -1,
        },
        to: {
            x: position_data.to.x,
            y: position_data.to.y * -1,
        },
    };
}

/**
 *  Get points from walls, they are needed to draw the floor in the visualizer
 *  exclude walls that are free
 */
export function getRoomPoints(walls: Wall[]){
    return walls.filter((wall) => !wall.free)
                .map((wall) => {
                    return wall.from;
                });
}

/**
 * Draw the walls in the visualizer
 */
export function drawUnityRoom(walls: Wall[], points: Coordinates[], room_height: number){
    const generalStore = useGeneralStore();

    ObjectRoom.draw(walls, points, room_height);

    //Set default sunstance
    new ObjectScene(generalStore.sunStance).update();

    event.emit("sceneBackground", { id: 2 });
}

/**
 *  Get the biggest size of all the walls
 */
export function getMaxValues(walls: Wall[]): Coordinates {
    let maxX: number, maxY: number;

    //check if walls undefined and return error
    if (!walls) {
        console.error("walls undefined");
        return null;
    }

    walls.forEach((wall) => {
        maxX = Math.max(maxX || 0, wall.from.x, wall.to.x);
        maxY = Math.max(maxY || 0, wall.from.y, wall.to.y);
    });

    return { x: maxX, y: maxY };
}

/**
 *  Get the center of the room
 */
export function getRoomCenter(walls: Wall[]): Coordinates {
    const points: Coordinates[] = [];

    // Collect unique vertices from walls
    walls.forEach(wall => {
        points.push(wall.from, wall.to);
    });

    // Remove duplicate vertices
    const uniqueVertices = Array.from(new Set(points.map(v => JSON.stringify(v))))
        .map(v => JSON.parse(v));

    const n = uniqueVertices.length;
    let area = 0;
    let Cx = 0;
    let Cy = 0;

    for (let i = 0; i < n; i++) {
        const x0 = uniqueVertices[i].x;
        const y0 = uniqueVertices[i].y;
        const x1 = uniqueVertices[(i + 1) % n].x;
        const y1 = uniqueVertices[(i + 1) % n].y;

        const a = x0 * y1 - x1 * y0;
        area += a;
        Cx += (x0 + x1) * a;
        Cy += (y0 + y1) * a;
    }

    area *= 0.5;
    const factor = 1 / (6 * area);
    Cx *= factor;
    Cy *= factor;

    return { x: Cx, y: Cy };
}

export function resetTint (graphics: any[], zIndex = 100): void {
    graphics.forEach(child => {
        child.tint = 0xffffff;
        child.zIndex = zIndex;
    });
};

function setWallMeasurements(container: Container, wall: Wall){
    const wallLength = parseFloat((wall.getLength() / 100).toFixed(2));

    const rectHeight = 34;
    const rectWidth = 44;

    const sizeBG = new Graphics();
    sizeBG.beginFill(0xf0f0f0);
    sizeBG.lineStyle(2, 0xffffff);
    sizeBG.drawRoundedRect(-rectWidth / 2, -rectHeight / 2, rectWidth, rectHeight, 14);
    sizeBG.endFill();

    const textItem = new BitmapText(wallLength + " m", {
        fontName: "Mukta",
        fontSize: 8,
        tint: 0x000000
    });
    textItem.anchor.set(0.5, 0.8);
    textItem.name = "measurementText"; // Set the name of the child

    sizeBG.addChild(textItem);
    sizeBG.visible = false;
    sizeBG.zIndex = 120;
    sizeBG.wall_id = wall.id;

    container.addChild(sizeBG);
}

/**
 * Update wall measurements
 */
export function updateWallMeasurements(parent: Container, wallId: number, updateText = false){
    const roomStore = useRoomStore();
    const currentWall = roomStore.getWall(wallId);

    if (!currentWall) {
        return;
    }

    const halfWallWidth = roomStore.wallThickness / 2;

    //Get the correct wall measurement
    const measurementGraphics = parent.children.find(
        child => child.wall_id === wallId
    );

    if(!measurementGraphics){ return }

    const midPoint = currentWall.getCenter();

    if(!currentWall.free){
        const innerVector = currentWall.getInnerVector();

        midPoint.x -= (halfWallWidth * innerVector.x);
        midPoint.y -= (halfWallWidth * innerVector.y);
    }

    measurementGraphics.angle = currentWall.getAngle() % 180;

    //Set the wall measurement to the correct position
    measurementGraphics.position.set(midPoint.x, midPoint.y);
    measurementGraphics.visible = true;

    if(!updateText){ return }

    //Update text
    const wallLength = parseFloat((currentWall.getLength() / 100).toFixed(2));

    measurementGraphics.getChildByName("measurementText").text = wallLength + " m";
}
