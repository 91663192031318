import * as visualizerAction from '@/utils/visualizer';
import * as ObjectUtils from "@/utils/objectUtils";
import type { ProductVariant } from '@/types/productvariant.types';
import type { Coordinates } from '@/types/coordinates.types';
import type { Zone } from '@/types/configurationmarkers.types';
import type { ProductGroup } from '@/types/productgroups.types';
import { useMarkerStore, useRoomStore } from '@/stores';
import { ConfigurationMarker } from './classes/ConfigurationMarker';
import { MarkerPoly, collisionSystem } from '@/types/collision.types';
import { Wall } from './classes/Wall';

/**
 * Remove the zones from the visualiser and the markerStore
 */
export function removeZones(configurationMarker: ConfigurationMarker, removeFromStore = false) {
    if (configurationMarker.zones && configurationMarker.zones.length > 0) {
        //remove old zones from visualizer by using the marker in markerStore
        configurationMarker.zones.forEach((zone) => {
            visualizerAction
                .createZoneObject(zone, configurationMarker.xid)
                .delete();
        });

		if(!removeFromStore){
			return;
		}

        //remove old zones from markerStore
        configurationMarker.zones = [];
    }
}

/**
 * Create zones add them to the configurationMarker
 * and send zones to the visualizer
 */
export function createZonesAndSendToVisualizer(
	configurationMarker: ConfigurationMarker,
	selectedVariant: ProductVariant,
	productGroup: ProductGroup,
	dragging = false,
	): void {
	//Create zones
	const zones = createZones(configurationMarker, productGroup, selectedVariant);

	// When you are draggign an object the object can be dragged into the room.
	// So only then we don't update the zones or you would lose the zones (pricing).
	if(dragging && zones.length <= 0){
		return
	}

	//Add them to the configurationMarker in the markerStore
	configurationMarker.zones = zones;

	//Send the zones to visualiser
	zones.forEach(zone => {
		visualizerAction.createZoneObject(zone, configurationMarker.xid, selectedVariant).create();
	})
}

function calculateDistance(point1: Coordinates, point2: Coordinates): number {
    return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
}

/**
 * Create new zones on closest walls
 *
 * @returns {Object} zones
 */
function createZones(
		configurationMarker: ConfigurationMarker,
		productGroup: ProductGroup,
		selectedVariant: ProductVariant,
	): Zone[] {
	const roomStore = useRoomStore();
	// const walls = Marker.getClosestWalls(configurationMarker);
	const walls = roomStore.room.floorplan_data.walls as Wall[];
    const zones = [];

	 //We get the marker from the collisionSystem
	 const markerBody = collisionSystem.all().find((body): body is MarkerPoly =>
		body instanceof MarkerPoly &&
		body.xid === configurationMarker.xid
	);

	if(!markerBody){
		return zones;
	}

	//Get the corners positions from the marker
	const markerCorners = markerBody.calcPoints.map(corner => ({
		x: corner.x + markerBody.pos.x,
		y: corner.y + markerBody.pos.y
	}));

	const { primaryProductVariant }  = configurationMarker;
	const objectHeight = primaryProductVariant?.height_offset ?? primaryProductVariant.height ?? 0;

	markerCorners.forEach((corner, index) => {
        //Combine corners to have sides
        const start = corner;
        const end = markerCorners[(index + 1) % markerCorners.length]; // Wrap around to the first point for the last side

        if (!end) {
            return
        }

		walls.forEach(wall => {
			let threshold = 2; // Adjust as needed

			const closestPointStart = wall.getClosestPointOnLine(start, false);
            const closestPointEnd = wall.getClosestPointOnLine(end, false);

            const distanceStart = calculateDistance(closestPointStart, start);
            const distanceEnd = calculateDistance(closestPointEnd, end);

			//Free walls are drawn from the center,
			//so the threshold should include half the wallthickness
			if(wall.free){
				threshold = threshold + (roomStore.wallThickness / 2)
			}

			if (distanceStart < threshold && distanceEnd < threshold) {
				const zoneHeight = getZoneHeight(wall, configurationMarker, selectedVariant);
                const startPointX = wall.getClosestPointOnLine(start);
            	const endPointX = wall.getClosestPointOnLine(end);

				//Determine the side of the wall for the zone
				//Always use front(inside)
				let wallSide = 'front';

				if(wall.free){
					//When not an outer wall check for side
					wallSide = ObjectUtils.wallSide(wall, markerBody.pos);
				}

				//Translate floorplan coordinates to wall coordinates
		        const positionOnWall = ObjectUtils.getPositionOnWall(wall, startPointX, wallSide === 'back');
		        const positionOnWallEnd = ObjectUtils.getPositionOnWall(wall, endPointX, wallSide === 'back');

				const zone = {
					id: zones.length,
					wall_id: wall.id,
					from: {x: Math.round(positionOnWall.x * 100) / 100, y: objectHeight},
					to: {x: Math.round(positionOnWallEnd.x * 100) / 100, y: zoneHeight},
             		side: wallSide,
					product_group_id: productGroup.id,
				}

				zones.push(zone);
            }
		});
	});

	return zones;
}

//The height of the zone is limited to the wall height
//When a wall cover height is available, limit the height to the wall cover
function getZoneHeight(
	wall: Wall,
	configurationMarker: ConfigurationMarker,
	selectedVariant: ProductVariant
	): number {
	const { primaryProductVariant }  = configurationMarker;

	const { wall_covering_height } = configurationMarker.productGroups.find((productGroup) => !!productGroup.wall_covering);
	const { fixed_wallcover_height } = selectedVariant;
	const objectHeight = primaryProductVariant.height_offset ?? primaryProductVariant.height;

	let zoneEnd = wall.height ?? 240;

	//Setting on product group
	//Fixed height for the wall covering, this will be used instead of the wall height
	if(wall_covering_height){
		zoneEnd = wall_covering_height + objectHeight;
	}

	//Setting on product category
	//this means the wallcover height cannot be larger then the product variant height from this category
	if(fixed_wallcover_height){
		zoneEnd = selectedVariant.length + objectHeight;
	}

	// Limit zoneEnd to a maximum of the wallheight
	zoneEnd = Math.min(zoneEnd, wall.height ?? 240);

	return zoneEnd;
}

export function updateZones(configurationMarker: ConfigurationMarker, dragging = false): void {
	// const markerStore = useMarkerStore();

	// Check if the marker manages zones
	if (!configurationMarker.managesZones()) {
		return;
	}

	// Remove any existing zones on our configuration marker.
	removeZones(configurationMarker);

	// get the first product group for our marker that has wall covering enabled.
	const zoneProductGroup = configurationMarker.getZoneProductGroup();

	// get any variant that is set for this product group, if available.
	const selectedVariant = configurationMarker.productVariants.find(
		(variant) => variant.product_group_id == zoneProductGroup.id
	);

	// If no variant is set, we won't generate zones.
	if (!selectedVariant) {
		return;
	}

	createZonesAndSendToVisualizer(
		configurationMarker,
		selectedVariant,
		zoneProductGroup,
		dragging,
	);

	selectedVariant.updateQuantity(configurationMarker);
}
